import React from 'react'
import './footer.scss'

import LogoFairway from '../images/logo-fairway.svg'
import LogoEho from '../images/logo-eho.svg'

function Footer(){
  const current_year = new Date().getFullYear();

  return(
    <footer>
      <div className="container">
        <div className="column">
          <a aria-label="Visit the Fairway website" href="https://fairwayindependentmc.com" rel="noreferrer" target="_blank"><LogoFairway /></a>
        </div>
        <div className="column column-text">
          <ul>
            <li>Copyright &copy;{current_year} Rob Kanyur</li>
            <li>Fairway Independent Morgage Corporation</li>
            <li>9977 N. 90th Street #150 | Scottsdale, AZ 85258</li>
          </ul>
        </div>
        <div className="column column-text">
          <ul>
            <li>NMLS #204420 &middot; NMLS Entity ID #2289</li>
            <li>AZ License #BK-0904162</li>
            <li>
              <a href="https://www.fairwayindependentmc.com/~/media/files/pdf/terms-of-use.pdf?la=en" rel="noreferrer" target="_blank">Terms of Use</a>&nbsp;&middot;&nbsp;
              <a href="https://www.fairwayindependentmc.com/resources/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>&nbsp;&middot;&nbsp;
              <a href="https://www.nmlsconsumeraccess.org/" rel="noreferrer" target="_blank">NMLS Consumer Access</a>
            </li>
          </ul>
        </div>
        <div className="column">
          <LogoEho />
        </div>
      </div>
    </footer>
  )
}

export default Footer
