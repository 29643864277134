import * as React from "react"
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'

import Layout from "../components/layout"
import "./index.scss"

const TemplateHomePage = ({ data }) => {
  console.log(data);
  return(
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <div className="container">
        <div className="column">
          <h1>Ensuring your financial security in retirement.</h1>
          <p>The reverse mortgage allows you to turn a portion of the equity in your home into cash. More than a million households are using a HECM reverse mortgage today (Source: HUD.gov)</p>
          <h2>Benefits of a Reverse Mortgage</h2>
          <ul>
            <li>No monthly mortgage payment – only taxes, insurance, and maintenance</li>
            <li>Increased discretionary cash flow</li>
            <li>You can still sell your home at any time</li>
          </ul>
          <div className="testimonials">
            <h2>Real Stories</h2>
            <div className="item">
              <p className="quote">&ldquo;I have always heard this was a long, challenging process, but it was so quick and easy with Rob! My friends can't believe how fast we were able to close. Communication was a breeze and responsiveness was a 10/10. Thank you, Rob!&rdquo;</p>
              <p className="quote-attrib">Kara R. (Peoria, AZ)</p>
            </div>
            <div className="item">
              <p className="quote">&ldquo;The Kanyur Team always respond quickly and professionally. They are willing to take time to answer all of your questions and explore all of your options. Rob makes the process easy, and I felt well informed and comfortable. I highly recommend them!&rdquo;</p>
              <p className="quote-attrib">James L. (Phoenix, AZ)</p>
            </div>
            <div className="item">
              <p className="quote">&ldquo;Rob was efficient, thorough, and a joy to work with. He moved us along the process without error and in the quickest way possible. I can't say enough good things about how painless securing our mortgage was. Thank you so much for your help, Rob.&rdquo;</p>
              <p className="quote-attrib">Clair B. (Chandler, AZ)</p>
            </div>
          </div>
          <h3>Video Placeholder</h3>
          <a className="button" href="/">Watch More Videos</a>
          <div className="row">
            <div className="column column-6">
              <h2>Get Your Free Book</h2>
              <p>Fill out this form and we will send you a free book of your choice!</p>
            </div>
            <div className="column column-6">
              <form>
                <label htmlFor="contactName">Your Name</label><input name="contactName" type="text" placeholder="Casey Clark" />
                <div className="row">
                  <div className="column column-10">
                    <label htmlFor="mailing01">Mailing Address</label><input name="mailing01" type="text" placeholder="555 N. Main Street" />
                  </div>
                  <div className="column column-2">
                    <label htmlFor="mailing02">Address Line 2*</label><input name="mailing02" type="text" placeholder="Unit 2" />
                  </div>
                </div>
                <label htmlFor="mailing_city">City</label><input name="mailing_city" type="text" placeholder="Mayberry" />
                <div className="row">
                  <div className="column column-6">
                    <label htmlFor="mailing_state">State</label>
                    <select name="mailing_state">
                      <option value="" disabled selected>Select</option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                  <div className="column column-6">
                    <label htmlFor="mailing_zip">Zip Code</label>
                    <input name="mailing_zip" type="text" placeholder="55555" />
                  </div>
                </div>
                <div className="captcha"><label htmlFor="captcha">I am not a robot</label><input name="captcha" type="checkbox" /></div>
              </form>
            </div>
          </div>
          <h2>The Kanyur Team at Fairway Independent Mortgage</h2>
          <p>With decades of combined experience in the mortgage industry, The Kanyur Team has helped hundreds of individuals and families make their retirement more secure.</p>
          <a className="button" href="/team">Meet the Team</a>
        </div>
      </div>
    </>
  )
}

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <TemplateHomePage data={frontmatter} />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export const pageQuery = graphql`
  query TemplateHomePage {
    markdownRemark(frontmatter: { layout: { eq: "home" } }) {
      frontmatter {
        title
      }
    }
  }
`


export default HomePage

