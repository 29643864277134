import React from 'react';
import './header.scss'

import LogoKanyurTeam from '../images/logo-kanyur-team.svg'

function Header(){
  return(
    <nav>
      <div className="container">
        <div className="column">
          <a aria-label="Link to the home page" href="/"><LogoKanyurTeam /></a>
        </div>
        <div className="nav-menu">
          <ul>
            <li><a href="/team">Meet the Team</a></li>
            <li><a href="https://fairwayreverse.com/loan-officers/rob-kanyur/" target="_blank" rel="noreferrer">Reverse Mortgages</a></li>
            <li><a href="/events">Events</a></li>
            <li><a href="/radio">Radio</a></li>
            <li><a href="/videos">Videos</a></li>
          </ul>
          <a href="/free-book" className="button">Get Your Free Book</a>
        </div>
      </div>
    </nav>
  )
}

export default Header
